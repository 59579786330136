<template>
    <div class="services">
        <HeadingComponent
            title="Choose the services for your </br>custom screening package."
            subtitle="Prices will be included on the final proposal once you have setup your services"
        />

        <ApplicationSelector @onApplicationSelected="onApplicationSelected" class="mt-12" />

        <template>
            <div class="mt-10 services__cards-container">
                <v-card elevation="0" class="card">
                    <h4>
                        Criminal Background
                    </h4>
                    <div class="card__group card__group--with-action">
                        <ul class="simple-list">
                            <li>Instant Nationwide & Instant International</li>
                            <li>National Sex Offender/Predator Search</li>
                            <li>Federal Database Search & FBI Most Wanted</li>
                            <li>County and State Databases</li>
                            <li>
                                65+ Terrorists Databases <span class="highlight ml-1">New</span>
                            </li>
                        </ul>
                        <v-spacer></v-spacer>
                        <div class="button-container align-self-end">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card">
                    <h4>
                        Collection of Electronic Signatures/Initials
                    </h4>
                    <div class="card__group card__group--with-action">
                        <ul class="simple-list">
                            <li>Resident Tenant Evaluation Template Forms</li>
                            <li>Vehicle Tenant Evaluation Template Forms</li>
                            <li>Pet Tenant Evaluation Template Forms</li>
                        </ul>
                        <v-spacer></v-spacer>
                        <div class="button-container align-self-end">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card">
                    <h4>
                        Basic Document Collection
                    </h4>
                    <div class="card__group card__group--with-action">
                        <ul class="simple-list">
                            <li>Photo ID</li>
                            <li>Marriage Certificate</li>
                            <li>Pet Photo ( optional )</li>
                        </ul>
                        <v-spacer></v-spacer>
                        <div class="button-container align-self-end">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card">
                    <h4>
                        Collection of Application Fees
                    </h4>
                    <div class="card__group card__group--with-action">
                        <ul class="simple-list">
                            <li>Staff Training</li>
                            <li>E-Statements (ACH Direct Deposit)</li>
                            <li class="simple-list__clean-item">
                                *An extra $10.00 fee will be charged if rebates checks apply
                            </li>
                        </ul>
                        <v-spacer></v-spacer>
                        <div class="button-container align-self-end">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card">
                    <h4>
                        Credit Report with SSN Validation
                    </h4>
                    <div class="card__group card__group--with-action">
                        <div class="card__wrap">
                            <p class="mb-0">
                                Subject to inspection approval
                                <v-btn x-small icon
                                    ><img src="../../assets/icons/warning.svg" alt="helper"
                                /></v-btn>
                            </p>
                            <a href="">learn More</a>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="button-container align-self-end">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card">
                    <h4>
                        Nationwide Evictions, Suits, Liens, and Judgements
                    </h4>
                    <div class="card__group card__group--with-action">
                        <div class="card__wrap">
                            <a href="">learn More</a>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="button-container align-self-end">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                </v-card>

                <v-card elevation="0" class="card">
                    <h4>
                        Standard Reference Verifications
                    </h4>
                    <div class="card__group card__group--with-action">
                        <div class="card__content">
                            <p>Select the verifications you would like to do for your applicants</p>
                            <div
                                v-for="option in verificationsOptions"
                                :key="option.value"
                                class="form__group"
                            >
                                <v-checkbox
                                    color="secondary"
                                    v-model="verifications"
                                    :label="option.name"
                                    :value="option.value"
                                    hide-details="auto"
                                    v-on:change="verificationsChange()"
                                ></v-checkbox>
                            </div>
                            <a class="mt-5" href="">learn More</a>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="button-container align-self-end">
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </div>
                </v-card>
            </div>

            <TotalResultComponent :total="35" member="Gold Member" />

            <div class="dashboard__actions mt-15">
                <div class="spacer"></div>
                <v-btn @click="$router.push('review')" color="secondary" x-large elevation="0">
                    continue
                </v-btn>
            </div>
        </template>
    </div>
</template>

<script>
import ApplicationSelector from '@/components/ApplicationSelector';
import HeadingComponent from '@/components/HeadingComponent';
import TotalResultComponent from '@/components/TotalResultComponent';

export default {
    name: 'Services',
    components: { HeadingComponent, ApplicationSelector, TotalResultComponent },
    data: () => {
        return {
            verifications: [],
            verificationsOptions: [
                { value: 'character', name: 'Character' },
                { value: 'landlord', name: 'Landlord' },
                { value: 'employment', name: 'Employment' },
                { value: 'bank-verification', name: 'Bank Verification' },
            ],
        };
    },
    methods: {
        onApplicationSelected(value) {
            alert(`You selected ${value}`);
        },
        /**
         * @todo remove this function once the purpose is known
         */
        verificationsChange() {
            console.log(this.verifications);
        },
    },
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';
</style>
